import React from 'react';
import {Helmet} from 'react-helmet';
import '../../assets/css/engagement-models.css';
import '../../assets/css/how.css';

export default () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Process | Tintash</title>
    </Helmet>
    <div className="how-container">
      <section className="engagement-section pt-5 pb-2">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <div className="heading latoBlack text-center mb-5">Process</div>
              <div className="row">
                <div className="card-container col-lg-12 col-md-6 col-sm-12 mb-5">
                  <div className="engagement-model__card card h-100 bg-transparent">
                    <div className="engagement-model__card-title latoBold mb-0 pt-4 pb-0 px-4 ">
                      Discovery and Scoping
                    </div>
                    <div className="engagement-model__card-body card-body mt-0 py-3 px-4 ">
                      <div>
                        A dedicated Technical Project Manager (TPM) conducts a Zoom meeting with the
                        customer, assuming responsibility for the project. This TPM collaborates
                        with a streamlined engineering team, embarking on a 4-week, $10,000 effort,
                        resulting in the following deliverables:
                      </div>
                      <br />
                      <ol>
                        <li style={{lineHeight: '1.75'}}>
                          Clear definition of customer requirements and objectives.
                        </li>
                        <li style={{lineHeight: '1.75'}}>Prioritization of a roadmap.</li>
                        <li style={{lineHeight: '1.75'}}>
                          Technical Proof of Concept (POC) results accompanied by a risk analysis.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Estimations of time and budget for the Go-To-Market Minimum Viable Product
                          (MVP).
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Establishment of a cross-functional team structure, including recommended
                          profiles.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="card-container col-lg-12 col-md-6 col-sm-12 mb-5">
                  <div className="engagement-model__card card h-100 bg-transparent">
                    <div className="engagement-model__card-title latoBold mb-0 pt-4 pb-0 px-4 ">
                      Project Initiation and Sprint to Market
                    </div>
                    <div className="engagement-model__card-body card-body mt-0 py-3 px-4 ">
                      <div>
                        We subsequently transition into an ongoing sprint cycle, with the TPM
                        orchestrating the onboarding of the selected cross-functional team. This
                        phase is dedicated to achieving Product-Market Fit, ensuring the following:
                      </div>
                      <br />
                      <ol>
                        <li style={{lineHeight: '1.75'}}>
                          Adoption of an agile process featuring 2-week sprints.
                        </li>
                        <li style={{lineHeight: '1.75'}}>Daily updates and sprint reports.</li>
                        <li style={{lineHeight: '1.75'}}>
                          Implementation of an automated code review process.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Execution of testable sprint demonstrations and product releases.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Consideration of product pivot decisions and team realignment.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Comprehensive reviews of architecture and infrastructure to ensure
                          effortless scalability and effective management of technical debt.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Oversight of budget and time constraints.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Cultivation of a high-performance and engagement culture, facilitated
                          through the utilization of Camaradly, our proprietary tool.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Regular monthly account management calls to gain insights into continuous
                          improvement, maintaining our commitment to being an exemplary partner with
                          a rating of 11/10.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          After the Go-To-Market MVP launch, we employ the Superhuman Product-Market
                          Fit test to implement a feedback loop that helps us gauge Product-Market
                          Fit and prioritize future steps.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>

                <div className="card-container col-lg-12 col-md-6 col-sm-12 mb-5">
                  <div className="engagement-model__card card h-100 bg-transparent">
                    <div className="engagement-model__card-title latoBold mb-0 pt-4 pb-0 px-4 ">
                      Growth Phase Sprints or Handoff
                    </div>
                    <div className="engagement-model__card-body card-body mt-0 py-3 px-4 ">
                      <ol>
                        <li style={{lineHeight: '1.75'}}>
                          Once Product-Market Fit is achieved, we transfer all relevant
                          documentation to the customer and assist in the recruitment, training, and
                          onboarding of new team members on the customer's side.
                        </li>
                        <li style={{lineHeight: '1.75'}}>
                          Alternatively, we offer scalable sets of managed teams to further support
                          your growth and operational needs.
                        </li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </>
);
