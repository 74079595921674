import React from 'react';
import * as ReactGA from 'react-ga';
import Layout from '../components/Shared/Layout';
import Process from '../components/Process';

const $ = typeof window !== `undefined` ? require('jquery') : null;
class ProcessPage extends React.Component {
  /* constructor(props) {
      super(props);
  } */
  componentDidMount() {
    ReactGA.set({
      title: 'Process.Page',
    });
    ReactGA.pageview(this.props.location.pathname);
  }
  render() {
    return (
      <Layout>
        <Process />
      </Layout>
    );
  }
}
export default ProcessPage;
